import Vue from "vue";
import {RouteEntity} from "@/core/entity/service/route";
import display from "@/configure/display/service/route";
import {PropValidator} from "vue/types/options";
import service from "@/core/service";
import {CompanyEntity} from "@/core/entity/core/company";


export default Vue.extend({
    name: "route-info-route",
    model: {prop: 'data'},
    props: {
        data: {type: Object} as PropValidator<RouteEntity>,
        display: {type: Object} as PropValidator<typeof display>,
        readonly: {type: Boolean},
        items: {},
    },
    data: () => ({
        show: true,
        companyList:[] as CompanyEntity[],
    }),
    async mounted() {
        this.companyList=await service.company.query({});
    },
    methods: {
        input(value: number) {
            // console.log(value);
        }
    },
});
