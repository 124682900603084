import Vue from "vue";
import {RoutePortEntity} from "@/core/entity/service/route";
import display from "@/configure/display/service/routePort";
import {DataOptions, DataTableHeader} from "vuetify";
import {PropValidator} from "vue/types/options";
import service from "@/core/service";
import {PortEntity} from "@/core/entity/common/port";


export default Vue.extend({
    name: "route-port",
    model: {prop: 'data'},
    inject: ["form"],
    props: {
        data: {type: Array} as PropValidator<RoutePortEntity[]>,
        display: {type: Object} as PropValidator<typeof display>,
    },
    data() {
        return {
            show: true,
            headers: <DataTableHeader[]>[
                {text: this.display.idx, value: 'idx', sortable: false, width: 150, align: 'center'},
                {text: this.display.portKey, value: 'portKey', type: 'input',width: 150, align: 'center'},
                {text: "", value: 'action', sortable: false, width: 150, align: 'center'},
            ],
            loadItem: [
                "Load",
                "Unload"
            ],
            options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: -1},
            portLoading: false,
            portItems: <PortEntity[]>[],
            portRunning: 0,
        }
    },
    methods: {
        add(index: number) {
            this.data.splice(index + 1, 0, {});
            for (let i = 0; i <this.data.length ; i++) {
                this.data[i].idx=i+1;
            }
        },
        del(index: number) {
            this.data.splice(index, 1);
            for (let i = 0; i <this.data.length ; i++) {
                this.data[i].idx=i+1;
            }
        },
        portQuery(val: string, item: { querying: boolean } & RoutePortEntity) {
            if (item.querying)
                return;
            // if (val === item.port)
            //     return;
            clearTimeout(this.portRunning);
            if (!val || val.length < 2)
                return;
            this.portRunning = setTimeout(async () => {
                Vue.set(item, "querying", true);
                try {
                    this.portItems = await service.port.query({name: "%"+val + "%", sq: {size: 10}});
                } finally {
                    Vue.set(item, "querying", false);
                }
            }, 300);
        },
        portInput(port: PortEntity, item: RoutePortEntity) {
            item.portKey = port?.key;
            this.portItems = [];
            // console.log(this.portItems);
        }
    },
    computed: {
        readonly() {
            // @ts-ignore
            return !!this.form && this.form.readonly;
        }
    }
});
