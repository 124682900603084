var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("港口管理 "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.add(_vm.data.length)}}},[_c('v-icon',[_vm._v(_vm._s('mdi-plus-thick'))])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('v-card-text',{attrs:{"hide-details":"auto"}},[_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider',{staticClass:"py-2"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"options":_vm.options,"hide-default-footer":"","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.portKey",fn:function(ref){
var item = ref.item;
return [_c('v-combobox',{attrs:{"value":item.name,"loading":item.querying,"items":_vm.portItems,"item-text":"name","no-filter":"","return-object":"","rules":[_vm.$validate.required()],"hide-no-data":"","hide-details":"auto","dense":""},on:{"update:search-input":function($event){return _vm.portQuery($event,item)},"input":function($event){return _vm.portInput($event,item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true)})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.readonly)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.add(index)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(!_vm.readonly)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.del(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }