import Vue from "vue";
import {RouteEntity} from "@/core/entity/service/route";
import display from "@/configure/display/service/route";
import service from "@/core/service";
import RoutePort from "./route-port.vue";
import RouteInfo from "./info-route.vue";
import {Verifiable} from "@/core/types/vuetify";

export default Vue.extend({
    components: {
        RoutePort,
        RouteInfo
    },
    data: () => ({
        key: void 0 as undefined | number,
        loading: false,
        readonly: false,
        saving: false,
        data: new RouteEntity(),
        display
    }),
    async mounted() {
        switch (this.$route.name) {
            case "route.add":
                break;
            case "route.load":
                this.readonly = true;
            case "route.edit":
                this.key = Number(this.$route.params.key);
                await this.load();
                break;
        }
        // console.log(this.data);
    },
    methods: {
        async load() {
            this.loading = true;
            try {
               const data = await service.route.load(this.key!);
                this.data=data;
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if ((<Verifiable>this.$refs.form).validate()) {
                this.saving = true;
                try {
                    if (this.key === void 0) {
                        this.data = await service.route.add(this.data);
                        this.key = this.data.key;
                    } else {
                        await service.route.save(this.data);
                        this.data = await service.route.load(this.key);
                    }
                    this.$message("保存成功!", "success");
                } finally {
                    this.saving = false;
                }
            }else
                this.$message("有数据填写错误!", "error");
        }
    }
});
