import {UmEntity, UmQuery} from "../core";



/**
 * (Entity)
 * @author syj
 * @date 2021-10-18 11:30
 */
export class RoutePortEntity extends UmEntity {
	//航路主键
	public routeKey?: number;
	//c_port主键
	public portKey?: number;
	//索引号，从0开始
	public idx?: number;
	//港口名称
	public name?:string;
}


/**
 * (Query)
 * @author syj
 * @date 2021-10-18 11:30
 */
export class RoutePortQuery extends UmQuery {

}

/**
 * (Entity)
 * @author syj
 * @date 2021-10-18 11:31
 */
export class RouteEntity extends UmEntity {
	//航线名称
	public name?: string;
	//前台用户公司主键
	public userCompanyKey?: number;
	//子表 List
	public portList?:RoutePortEntity[]=[];

}


/**
 * (Query)
 * @author syj
 * @date 2021-10-18 11:31
 */
export class RouteQuery extends UmQuery {

	//航线名称
	public name?: string;
	//前台用户公司主键
	public userCompanyKey?: number;
}

