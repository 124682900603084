export default {
    routeKey: "航路主键",
    portKey: "c_port主键",
    idx: "索引号，从0开始",
    name: "航线名称",
    createTime: "创建时间",
    operTime: "操作时间",
    userCompanyKey: "公司",
    operOperatorKey: "操作人",
    operOperatorName: "操作人",
    portList: {routeKey: "航路主键", portKey: "港口", idx: "序号", name:"港口名称"}
}
